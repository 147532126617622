import { useEffect, useState } from "react";
import { Linking, StyleSheet, View } from "react-native";
import {
  Button,
  Card,
  Checkbox,
  List,
  Modal,
  Paragraph,
  Portal,
  SegmentedButtons,
  Switch,
  Text,
  TextInput
} from "react-native-paper";

const ImageCard = ({ item, visible, onClose, onSave }) => {
  const [isSold, setIsSold] = useState(item?.is_sold);
  const [soldAt, setSoldAt] = useState(item?.sold_at ? item.sold_at : "ebay");
  const [soldPrice, setSoldPrice] = useState(
    item?.sold_price ? item.sold_price : ""
  );

  useEffect(() => {
    if (item) {
      setIsSold(item.is_sold);
      setSoldAt(item.sold_at);
      setSoldPrice(item.sold_price);
    }
  }, [item]);

  return (
      <Portal>
        <Modal
          visible={visible}
          animationType="slide"
          onDismiss={onClose}
          contentContainerStyle={styles.containerStyle}
        >
          {item ? (
            <Card style={styles.card}>
              <Card.Cover
                source={{ uri: item.image.url }}
                resizeMode="contain"
              />
              <Card.Title
                titleStyle={styles.title}
                title={item.name}
                titleNumberOfLines={3}
                titleVariant="displaySmall"
              />
              <Card.Content>
                <View style={styles.content}>
                  <Text style={styles.text}>
                    Start Price:{" "}
                    <Text style={styles.money}>
                      {item.currency} {item.start_range}
                    </Text>{" "}
                  </Text>
                  <Text style={styles.text}>
                    Low Range:{" "}
                    <Text style={styles.money}>
                      {item.currency} {item.low_range}
                    </Text>
                  </Text>
                  {item.ebay_item_number && (
                    <Text
                      style={{ color: "blue" }}
                      onPress={() =>
                        Linking.openURL(
                          "https://www.ebay.com/itm/" + item?.ebay_item_number
                        )
                      }
                    >
                      Ebay Listing
                    </Text>
                  )}
                </View>
              </Card.Content>
              <Card.Content>
                <View style={styles.content_col}>
                  <>
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Switch
                        value={isSold}
                        onValueChange={() => {
                          setIsSold(!isSold);
                        }}
                      />
                      <Paragraph style={{ ...styles.text, marginLeft: 10 }}>
                        Is Sold
                      </Paragraph>
                    </View>

                    {isSold && (
                      <View style={styles.content_col}>
                        <List.Section
                          title={`Sold At`}
                          style={{ fontSize: "small" }}
                        >
                          <SegmentedButtons
                            value={soldAt}
                            onValueChange={setSoldAt}
                            buttons={[
                              {
                                value: "ebay",
                                label: "Ebay",
                                style: styles.button,
                              },
                              // {
                              //   value: "site",
                              //   label: "Site",
                              //   style: styles.button,
                              // },
                              {
                                value: "expo",
                                label: "Expo",
                                style: styles.button,
                              },
                              {
                                value: "store",
                                label: "Store",
                                style: styles.button,
                              },
                            ]}
                            style={styles.group}
                          />
                        </List.Section>
                        <TextInput
                          left={<TextInput.Affix text={item.currency} />}
                          label={"Sold Price"}
                          placeholder="sold for"
                          value={soldPrice?.toString()}
                          onChangeText={(text) =>
                            setSoldPrice(text.replace(/[^0-9]/g, ""))
                          }
                        />
                      </View>
                    )}
                  </>
                </View>
              </Card.Content>
              <Card.Actions>
                <Button onPress={onClose}>Close</Button>
                <Button
                  onPress={() => {
                    onSave(item.id, isSold, soldAt, soldPrice);
                  }}
                >
                  Save
                </Button>
                {/* {!item.is_sold && (
             
            )} */}
              </Card.Actions>
            </Card>
          ) : null}
        </Modal>
      </Portal>
  );
};

export default ImageCard;

const styles = StyleSheet.create({
  containerStyle: {
    backgroundColor: "white",
    padding: 20,
  },
  card: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  content: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingVertical: 20,
  },
  content_col: {
    flexDirection: "column",
    maxWidth: "300px",
    paddingHorizontal: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: "blue",
    textTransform: "uppercase",
    lineHeight: 18,
  },
  text: {
    fontSize: 16,
    fontWeight: "bold",
  },
  button: {
    flex: 1,
  },
  money: {
    color: "blue",
  },
  group: { paddingHorizontal: 20, justifyContent: "center" },
});
