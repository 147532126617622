import React, { useCallback, useEffect, useState } from "react";
import {
  Dimensions,
  FlatList,
  Platform,
  RefreshControl,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from "react-native";
import {
  Appbar,
  Badge,
  Card,
  DefaultTheme,
  Provider as PaperProvider,
  Paragraph,
  Portal,
  ProgressBar,
  Searchbar
} from "react-native-paper";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import Auth from "./components/Auth";

import ImageCard from "./components/ImageCard";
import useDebounce from "./hooks/useDebounce";
import { supabase } from "./util/supabase";

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: "#092038",
    accent: "yellow",
  },
};

const PhotoItem = ({ item, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View key={item.id} style={styles.item}>
        <Card style={styles.card}>
          {item.is_sold ? (
            <Badge style={styles.badge} size={40}>
              Sold
            </Badge>
          ) : null}
          <Card.Cover source={{ uri: item.image.url }} resizeMode="contain" />
          <Card.Content>
            <Paragraph>{item.name}</Paragraph>
          </Card.Content>
        </Card>
      </View>
    </TouchableOpacity>
  );
};

export default function App() {
  const [refreshing, setRefreshing] = useState(false);
  const [filteredCards, setFilteredCards] = useState([]);
  const [filterText, setFilterText] = useState("");
  const debouncedSearch = useDebounce(filterText, 2000);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    onRefresh();
  }, [debouncedSearch]);

  const getData = async (searchText) => {
    try {
      //console.log(searchText);
      const { data, error } = await supabase
        .from("cards")
        .select("*")
        .ilike("name", `%${searchText}%`)
        .eq("enabled", true)
        .limit(30);
      //console.log("result", data, error);
      if (!error) {
        return data;
      }
    } catch (e) {
      // error reading value
      console.log(e);
    }
  };

  const onRefresh = async () => {
    setRefreshing(true);
    if (debouncedSearch) {
      const data = await getData(debouncedSearch);
      setFilteredCards(data);
    } else {
      setFilteredCards([]);
    }
    setRefreshing(false);
  };

  const renderItem = ({ item }) => {
    return <PhotoItem item={item} onPress={() => onItemSelect(item)} />;
  };

  const onItemSelect = (item) => {
    setSelectedItem(item);
    setModalIsVisible(true);
  };

  const onItemClose = () => {
    setModalIsVisible(false);
    setSelectedItem(null);
  };

  const onItemSave = async (id, isSold, soldAt, soldFor) => {
    onItemClose();
    const { data, error } = await supabase
      .from("cards")
      .update({
        is_sold: isSold,
        sold_at: soldAt,
        sold_price: soldFor,
        sold_date: isSold ? new Date().toISOString() : null,
      })
      .match({ id: id });

    // update the data in the list
    const newData = filteredCards.map((card) => {
      if (card.id === id) {
        card.is_sold = isSold;
        card.sold_at = soldAt;
        card.sold_price = soldFor;
      }
      return card;
    });
    setFilteredCards(newData);
  };

  const [session, setSession] = useState(null);

  useEffect(() => {
    const sessionUser = supabase.auth.user();
    setSession(sessionUser);

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  return (
    <SafeAreaProvider>
      <PaperProvider theme={theme}>
        <SafeAreaView>
          <>
            <Appbar.Header>
              <Appbar.Content title="CNC Singles" />
            </Appbar.Header>
            {session && session.user ? (
              <>
                <Searchbar
                  placeholder="Search"
                  onChangeText={(query) => setFilterText(query)}
                  value={filterText}
                  style={styles.searchbar}
                />
                <View style={styles.searchContainer}>
                  {filteredCards && filteredCards.length > 0 ? (
                    <ScrollView
                      style={{
                        maxHeight: 0.8 * Dimensions.get("window").height,
                      }}
                    >
                      <FlatList
                        contentContainerStyle={styles.content}
                        data={filteredCards}
                        renderItem={renderItem}
                        keyExtractor={(item) => item.id}
                        numColumns={2}
                        refreshControl={
                          <RefreshControl
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                          />
                        }
                      />
                    </ScrollView>
                  ) : (
                    <View style={{ marginVertical: 10 }}>
                      <Paragraph>
                        Please type in search box to see results.
                      </Paragraph>
                      <ProgressBar indeterminate visible={refreshing} />
                      {filterText &&
                        filteredCards &&
                        filteredCards.length < 0 && (
                          <Paragraph>
                            No cards found with that search query.
                          </Paragraph>
                        )}
                    </View>
                  )}

                  {modalIsVisible && (
                    <ImageCard
                      visible={modalIsVisible}
                      item={selectedItem}
                      onClose={onItemClose}
                      onSave={onItemSave}
                    />
                  )}

                  {/* <Text>Pull down to see RefreshControl indicator</Text> */}
                </View>
              </>
            ) : (
              <Auth />
            )}
          </>
        </SafeAreaView>
      </PaperProvider>
    </SafeAreaProvider>
  );
}
const styles = StyleSheet.create({
  ...Platform.select({
    default: {
      content: {
        flexDirection: "column",
        padding: 4,
        //flexWrap: "wrap"
      },
      item: {
        width: Dimensions.get("window").width / 2 - 4,

        padding: 4,
      },
    },
  }),
  photo: {
    flex: 1,
    resizeMode: "cover",
    aspectRatio: "auto",
  },
  title: {
    fontSize: 12,
    paddingHorizontal: 12,
    marginHorizontal: 12,
  },
  searchbar: {
    margin: 4,
  },
  searchContainer: {
    flexDirection: "column",
    overflow: "scroll",
    // height: "90%",
  },
  card: {
    margin: 4,
    maxWidth: "300px",
    position: "relative",
  },
  badge: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "red",
    color: "white",
    zIndex: 1,
    fontSize: 16,
  },
});
